import AgentImage1 from "assets/png/agent/image1.png";

export const AGENT_IMAGES: any = {
  image1: AgentImage1,
};

export const AGENT_MOCK_RESPONSE = {
  ErrorMsg: "I'm sorry, something went wrong. Please try again.",
  IrrelevantErrorMsg: "I'm sorry, Your question is not relevent to this conversation. Please try again.",
  CompanyUrlNoActionResponse:
    "No problem—let’s make this right. I’ve placed the current details about your company in the chat below. Feel free to edit them directly or type in the correct information.",
  SelectGoalResponse: "Now, lets get you to providing your goal",
  EditCompanyInfoResponse: "Alright, here’s what I’ve gathered so far about your company:",
  Great: "Great!",
  Perfect: "Perfect!",
  MoveToNextStep: "Lets proceed to the next step",
  InfoAboutFileCollections1: "Just a quick note—I'm collecting these files and documents to get a deeper understanding of your business.",
  InfoAboutFileCollections2: `Benefits: Uploading relevant file allows me to:
              
- Understand your current strategy.
- Provide precise optimizations tailored to your goals.
`,
  InfoAboutFileCollections3: `Suggestions for what to upload:

- Past marketing budget.
- Performance data (e.g., campaign ROI, engagement metrics).
- Marketing plans or campaign strategies.
`,
  GoalAlignResponse:
    "Your goal aligns well with your business stage. Now, let’s move on to set the budget period to keep everything on track.",
  StartGoalSuggestions:
    "Seems like you've added the goal, but let me double check if the goals are right for the stage of your business.",
  MoveToStep3Response: "Let’s build your detailed marketing plan.",
  Step3: {
    GreatChoice: "Great choice! Tell me what to include so I can optimize it for your goals.",
  },
  StartCompanyWebsiteAnalysis: "Let me dive into your website and gather the details I need.",
};

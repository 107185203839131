import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { apiCreateWorkflow, apiGetAllWorkflows, apiGetWorkflow, apiGetWorkflowStrategyTemplate } from "api/workflows";

import { Meta } from "interfaces/meta.interfaces";
import { CreateWorkflowPayload, Workflow, WorkflowListItem } from "interfaces/workflows.interfaces";

export const workflowQueryKeys = {
  getWorkflowStrategyTemplates: (templateType: string) => ["getWorkflowStrategyTemplates", templateType] as const,
  getAllWorkflow: (payload: Meta) => ["getAllWorkflow", { ...payload }] as const,
  getWorkflow: (id?: number) => ["getWorkflow", id] as const,
  createWorkflow: (payload?: { strategy_id?: number; project_id?: number }) => ["createWorkflow", { ...payload }] as const,
};

export const useGetWorkflowStrategyTemplate = (templateType: string) =>
  useQuery({
    queryKey: workflowQueryKeys.getWorkflowStrategyTemplates(templateType),
    queryFn: async (): Promise<
      Array<{
        strategy_id: number;
        name: string;
        description: string;
        additional_fields: Array<string>;
        channel_id: number;
        label?: string;
        slug: string;
      }>
    > => {
      return await apiGetWorkflowStrategyTemplate(templateType);
    },
  });

export const useGetAllWorkflow = (payload: Meta) => {
  return useQuery({
    queryKey: workflowQueryKeys.getAllWorkflow(payload),
    queryFn: async (): Promise<WorkflowListItem[]> => {
      return await apiGetAllWorkflows(payload);
    },
  });
};

export const useGetWorkflow = (id?: number) => {
  return useQuery({
    queryKey: workflowQueryKeys.getWorkflow(id),
    queryFn: async (): Promise<Workflow> => {
      if (!id) {
        return Promise.reject("No id provided");
      }

      return await apiGetWorkflow(id);
    },
  });
};

export const useCreateWorkflowQuery = (payload?: { strategy_id?: number; project_id?: number }) => {
  return useQuery({
    queryKey: workflowQueryKeys.createWorkflow(payload),
    queryFn: async (): Promise<Workflow> => {
      if (!payload) {
        return Promise.reject("No payload provided");
      }

      if (!payload.strategy_id || !payload.project_id) {
        return Promise.reject("No strategy_id or project_id provided");
      }

      return apiCreateWorkflow({
        strategy_id: payload.strategy_id,
        project_id: payload.project_id,
      });
    },
  });
};

export const useCreateWorkflow = () => {
  const queryClient = useQueryClient();

  return useMutation<Workflow, any, CreateWorkflowPayload>({
    mutationFn: async data => apiCreateWorkflow(data),
    onSuccess: () => {
      //queryClient.invalidateQueries({ queryKey: businessKeys.getBusinessTeam });
    },
  });
};

export const ROUTES = {
  home: "/",
  dashboard: "/dashboard",
  workflowDetails: "/workflow/:id",
  memberships: "/memberships",
  personalize: "/personalize",
  signIn: "/sign-in",
  signInWithToken: "/sign-in/:token?",
  signUp: "/sign-up",
  resetPassword: "/reset-password",
  confirmPassword: "/confirm-password",
  setupPassword: "/setup-password",
  verifyCode: "/verify-code",
  verifyEmail: "/verify-email",
  chart: "/chart",
  budget: "/budget",
  budgetForm: "/budget/form",
  editBudgetForm: "/budget/form/edit",
  uploadBudgetForm: "/uploadBudget",
  budgetOverview: "/budget/overview",
  budgetIntelligence: "/budget/:id/budget-intelligence",
  workflow: "/workflow",
  agents: "/agents",
  projects: "/projects",
  taskProjects: "/projects?filter=task",
  workflowProjects: "/projects?filter=workflow",
  performancePlan: "/performance-plan/:id",
  createPerformancePlan: "/performance-plan/form",
  connectSources: "/integrations/connect-sources",
  connectedSources: "/integrations/connected-sources",
  connectedSourcesDetails: "/integrations/connected-sources/:id",
  setupCompanyProfile: "/company-profile",
  termsOfService: "https://www.brainvoy.ai/terms",
  privacyPolicy: "https://www.brainvoy.ai/privacy",
};

//Review Budget Demo Data
export const headerData = [
  "Channel",
  "2024-01",
  "2024-02",
  "2024-03",
  "2024-04",
  "2024-05",
  "2024-06",
  "2024-07",
  "2024-08",
  "2024-09",
  "2024-10",
  "2024-11",
  "2024-12",
  "Total",
];

export const budgetData = [
  {
    id: "1",
    name: "Social Media",
    expanded: true,
    isParent: true,
    project_channel_id: null,
    "2024-01": 195000,
    "2024-02": 195000,
    "2024-03": 195000,
    "2024-04": 163270,
    "2024-05": 226120,
    "2024-06": 17866,
    "2024-07": 195000,
    "2024-08": 195000,
    "2024-09": 195000,
    "2024-10": 163270,
    "2024-11": 226120,
    "2024-12": 17866,
  },
  {
    id: "239e9a10-ad42-4086-8b3a-fc04c882c9d1",
    parentId: "1",
    name: "Social Media",
    channel_id: 24,
    "2024-01": 97500,
    "2024-02": 97500,
    "2024-03": 97500,
    "2024-04": 81635,
    "2024-05": 113060,
    "2024-06": 8933,
    "2024-07": 97500,
    "2024-08": 97500,
    "2024-09": 97500,
    "2024-10": 81635,
    "2024-11": 113060,
    "2024-12": 8933,
  },
  {
    id: "5adf4a00-58f8-47c7-a624-d0479511d444",
    parentId: "1",
    name: "Twitter",
    channel_id: 27,
    "2024-01": 10000,
    "2024-02": 10000,
    "2024-03": 10000,
    "2024-04": 5000,
    "2024-05": 44114,
    "2024-06": 4747,
    "2024-07": 10000,
    "2024-08": 10000,
    "2024-09": 10000,
    "2024-10": 5000,
    "2024-11": 44114,
    "2024-12": 4747,
  },
  {
    id: "c500a99d-34c9-4ab7-b03f-10902142f88f",
    parentId: "1",
    name: "Facebook",
    channel_id: 25,
    "2024-01": 25000,
    "2024-02": 25000,
    "2024-03": 25000,
    "2024-04": 1441,
    "2024-05": 45000,
    "2024-06": 1717,
    "2024-07": 25000,
    "2024-08": 25000,
    "2024-09": 25000,
    "2024-10": 1441,
    "2024-11": 45000,
    "2024-12": 1717,
  },
  {
    id: "84c0a23d-12c4-4959-a1f3-e3df61a1013a",
    parentId: "1",
    name: "Pinterest",
    channel_id: 29,
    "2024-01": 3500,
    "2024-02": 3500,
    "2024-03": 3500,
    "2024-04": 2422,
    "2024-05": 2472,
    "2024-06": 2227,
    "2024-07": 3500,
    "2024-08": 3500,
    "2024-09": 3500,
    "2024-10": 2422,
    "2024-11": 2472,
    "2024-12": 2227,
  },
  {
    id: "398bebcf-094c-4135-aa86-46a23e7fc37e",
    parentId: "1",
    name: "Instagram",
    channel_id: 26,
    "2024-01": 45000,
    "2024-02": 45000,
    "2024-03": 45000,
    "2024-04": 72772,
    "2024-05": 21474,
    "2024-06": 242,
    "2024-07": 45000,
    "2024-08": 45000,
    "2024-09": 45000,
    "2024-10": 72772,
    "2024-11": 21474,
    "2024-12": 242,
  },
  {
    id: "035680b2-0ebd-484f-bf51-ac95ef92d569",
    parentId: "1",
    name: "LinkedIn",
    channel_id: 28,
    "2024-01": 14000,
    "2024-02": 14000,
    "2024-03": 14000,
    "2024-04": 0,
    "2024-05": 0,
    "2024-06": 0,
    "2024-07": 14000,
    "2024-08": 14000,
    "2024-09": 14000,
    "2024-10": 0,
    "2024-11": 0,
    "2024-12": 0,
  },
  {
    id: "2",
    name: "Paid Ads",
    expanded: true,
    isParent: true,
    project_channel_id: null,
    "2024-01": 285255,
    "2024-02": 282755,
    "2024-03": 675255,
    "2024-04": 149636,
    "2024-05": 442727,
    "2024-06": 615252,
    "2024-07": 332502,
    "2024-08": 735199,
    "2024-09": 749977,
    "2024-10": 155750,
    "2024-11": 306904,
    "2024-12": 148888,
  },
  {
    id: "ea01b1d2-7778-44ec-a8c2-42a5910a3132",
    parentId: "2",
    name: "Banner Ads",
    channel_id: 21,
    "2024-01": 10000,
    "2024-02": 25000,
    "2024-03": 400000,
    "2024-04": 8000,
    "2024-05": 150000,
    "2024-06": 471111,
    "2024-07": 57247,
    "2024-08": 477444,
    "2024-09": 474722,
    "2024-10": 14114,
    "2024-11": 14177,
    "2024-12": 4747,
  },
  {
    id: "9525747f-7480-4b34-9c05-1dd7a12fd105",
    parentId: "2",
    name: "Sponsored Content",
    channel_id: 22,
    "2024-01": 20000,
    "2024-02": 2500,
    "2024-03": 20000,
    "2024-04": 141414,
    "2024-05": 20000,
    "2024-06": 141414,
    "2024-07": 20000,
    "2024-08": 2500,
    "2024-09": 20000,
    "2024-10": 141414,
    "2024-11": 20000,
    "2024-12": 141414,
  },
  {
    id: "9a31de97-76e5-4f3d-b221-abfbf184632c",
    parentId: "2",
    name: "Pay-Per-Click Marketing",
    channel_id: 20,
    "2024-01": 255255,
    "2024-02": 255255,
    "2024-03": 255255,
    "2024-04": 222,
    "2024-05": 272727,
    "2024-06": 2727,
    "2024-07": 255255,
    "2024-08": 255255,
    "2024-09": 255255,
    "2024-10": 222,
    "2024-11": 272727,
    "2024-12": 2727,
  },
  {
    id: "4",
    name: "Content",
    expanded: true,
    isParent: true,
    project_channel_id: null,
    "2024-01": 356255,
    "2024-02": 685497,
    "2024-03": 356255,
    "2024-04": 406373,
    "2024-05": 374727,
    "2024-06": 408878,
    "2024-07": 358255,
    "2024-08": 687497,
    "2024-09": 479669,
    "2024-10": 283959,
    "2024-11": 495141,
    "2024-12": 286464,
  },
  {
    id: "7b85d396-8315-4e83-9b00-f3b4248f33ce",
    parentId: "4",
    name: "Webinars",
    channel_id: 31,
    "2024-01": 20000,
    "2024-02": 141414,
    "2024-03": 20000,
    "2024-04": 141414,
    "2024-05": 20000,
    "2024-06": 141414,
    "2024-07": 20000,
    "2024-08": 141414,
    "2024-09": 141414,
    "2024-10": 20000,
    "2024-11": 141414,
    "2024-12": 20000,
  },
  {
    id: "48fb7076-85a4-4396-a017-ae89be14707a",
    parentId: "4",
    name: "Content Marketing",
    channel_id: 31,
    "2024-01": 60000,
    "2024-02": 146414,
    "2024-03": 60000,
    "2024-04": 222296,
    "2024-05": 60000,
    "2024-06": 222296,
    "2024-07": 60000,
    "2024-08": 146414,
    "2024-09": 60000,
    "2024-10": 222296,
    "2024-11": 60000,
    "2024-12": 222296,
  },
  {
    id: "12cba009-f7ef-4b01-824c-a542e0e3bb00",
    parentId: "4",
    name: "White Papers / ebooks",
    channel_id: 37,
    "2024-01": 20000,
    "2024-02": 141414,
    "2024-03": 20000,
    "2024-04": 40441,
    "2024-05": 20000,
    "2024-06": 40441,
    "2024-07": 20000,
    "2024-08": 141414,
    "2024-09": 20000,
    "2024-10": 40441,
    "2024-11": 20000,
    "2024-12": 40441,
  },
  {
    id: "0905ddba-374e-4899-b3a2-bb9ef33d39d2",
    parentId: "4",
    name: "Blog",
    channel_id: 32,
    "2024-01": 1000,
    "2024-02": 1000,
    "2024-03": 1000,
    "2024-04": 2000,
    "2024-05": 2000,
    "2024-06": 2000,
    "2024-07": 3000,
    "2024-08": 3000,
    "2024-09": 3000,
    "2024-10": 1000,
    "2024-11": 1000,
    "2024-12": 1000,
  },
  {
    id: "3c93bef6-5df8-45aa-b092-75a2e6f92b04",
    parentId: "4",
    name: "SEO",
    channel_id: 33,
    "2024-01": 255255,
    "2024-02": 255255,
    "2024-03": 255255,
    "2024-04": 222,
    "2024-05": 272727,
    "2024-06": 2727,
    "2024-07": 255255,
    "2024-08": 255255,
    "2024-09": 255255,
    "2024-10": 222,
    "2024-11": 272727,
    "2024-12": 2727,
  },
  {
    id: "5",
    name: "Email",
    expanded: true,
    isParent: true,
    project_channel_id: null,
    "2024-01": 2500,
    "2024-02": 15151,
    "2024-03": 655,
    "2024-04": 725,
    "2024-05": 11727,
    "2024-06": 24242,
    "2024-07": 2500,
    "2024-08": 15151,
    "2024-09": 655,
    "2024-10": 725,
    "2024-11": 11727,
    "2024-12": 24242,
  },
  {
    id: "8a1cfaac-6b81-40a6-ad96-25e6e81b9ee9",
    parentId: "5",
    name: "Email Newsletter",
    channel_id: 39,
    "2024-01": 2500,
    "2024-02": 15151,
    "2024-03": 655,
    "2024-04": 725,
    "2024-05": 11727,
    "2024-06": 24242,
    "2024-07": 2500,
    "2024-08": 15151,
    "2024-09": 655,
    "2024-10": 725,
    "2024-11": 11727,
    "2024-12": 24242,
  },
  {
    id: "7",
    name: "Mobile",
    expanded: true,
    isParent: true,
    project_channel_id: null,
    "2024-01": 12500,
    "2024-02": 25151,
    "2024-03": 10655,
    "2024-04": 3225,
    "2024-05": 14227,
    "2024-06": 26742,
    "2024-07": 12500,
    "2024-08": 25151,
    "2024-09": 10655,
    "2024-10": 3225,
    "2024-11": 14227,
    "2024-12": 26742,
  },
  {
    id: "fe648ac7-8996-488b-9639-b43d3d25c7a9",
    parentId: "7",
    name: "Mobile App",
    channel_id: 42,
    "2024-01": 2500,
    "2024-02": 15151,
    "2024-03": 655,
    "2024-04": 725,
    "2024-05": 11727,
    "2024-06": 24242,
    "2024-07": 2500,
    "2024-08": 15151,
    "2024-09": 655,
    "2024-10": 725,
    "2024-11": 11727,
    "2024-12": 24242,
  },
  {
    id: "7429d122-83ef-4008-8b67-c6801240e88c",
    parentId: "7",
    name: "Mobile Alerts",
    channel_id: 45,
    "2024-01": 10000,
    "2024-02": 10000,
    "2024-03": 10000,
    "2024-04": 2500,
    "2024-05": 2500,
    "2024-06": 2500,
    "2024-07": 10000,
    "2024-08": 10000,
    "2024-09": 10000,
    "2024-10": 2500,
    "2024-11": 2500,
    "2024-12": 2500,
  },
];

// Categories Tab Demo Data
export const categoriesData = [
  {
    id: "0",
    content: "0",
    display_order: 1,
    items: [
      { id: "National Marketing", content: "National Marketing", channel_id: 1 },
      { id: "Local Marketing", content: "Local Marketing", channel_id: 1 },
      { id: "Newspaper", content: "Newspaper", channel_id: 3 },
      { id: "In-Store Marketing", content: "In-Store Marketing", channel_id: 1 },
      { id: "POP", content: "POP", channel_id: 1 },
      { id: "Public Relations", content: "Public Relations", channel_id: 1 },
      { id: "Public Events", content: "Public Events", channel_id: 14 },
      { id: "Sponsorships", content: "Sponsorships", channel_id: 16 },
      { id: "Press Releases", content: "Press Releases", channel_id: 1 },
      { id: "Conferences", content: "Conferences", channel_id: 15 },
      { id: "Client Events", content: "Client Events", channel_id: 14 },
      { id: "Landing Page", content: "Landing Page", channel_id: 18 },
      { id: "Online", content: "Online", channel_id: 18 },
      { id: "Website", content: "Website", channel_id: 18 },
      { id: "Advertising", content: "Advertising", channel_id: 1 },
      { id: "Print", content: "Print", channel_id: 2 },
      { id: "Outdoor", content: "Outdoor", channel_id: 10 },
      { id: "Radio", content: "Radio", channel_id: 9 },
      { id: "Television", content: "Television", channel_id: 8 },
      { id: "Web", content: "Web", channel_id: 18 },
      { id: "Development", content: "Development", channel_id: 18 },
      { id: "Market Research", content: "Market Research", channel_id: 51 },
      { id: "Surveys", content: "Surveys", channel_id: 51 },
      { id: "Impact Studies", content: "Impact Studies", channel_id: 51 },
      { id: "Other", content: "Other", channel_id: 51 },
      { id: "Premiums", content: "Premiums", channel_id: 51 },
      { id: "Corporate Branding", content: "Corporate Branding", channel_id: 51 },
      { id: "Business Cards", content: "Business Cards", channel_id: 5 },
      { id: "Signage", content: "Signage", channel_id: 10 },
      { id: "TOTALS", content: "TOTALS", channel_id: 0 },
    ],
  },
  {
    id: "1",
    content: "1",
    display_order: 2,
    items: [
      { id: "Social Media", content: "Social Media", channel_id: 24 },
      { id: "Twitter", content: "Twitter", channel_id: 27 },
      { id: "Facebook", content: "Facebook", channel_id: 25 },
      { id: "Pinterest", content: "Pinterest", channel_id: 29 },
      { id: "Instagram", content: "Instagram", channel_id: 26 },
      { id: "LinkedIn", content: "LinkedIn", channel_id: 28 },
    ],
  },
  {
    id: "2",
    content: "2",
    display_order: 0,
    items: [
      { id: "Banner Ads", content: "Banner Ads", channel_id: 21 },
      { id: "Sponsored Content", content: "Sponsored Content", channel_id: 22 },
      { id: "Pay-Per-Click Marketing", content: "Pay-Per-Click Marketing", channel_id: 20 },
    ],
  },
  { id: "3", content: "Influencer", items: [] },
  {
    id: "4",
    content: "4",
    display_order: 0,
    items: [
      { id: "Webinars", content: "Webinars", channel_id: 31 },
      { id: "Content Marketing", content: "Content Marketing", channel_id: 31 },
      { id: "White Papers / ebooks", content: "White Papers / ebooks", channel_id: 37 },
      { id: "Blog", content: "Blog", channel_id: 32 },
      { id: "SEO", content: "SEO", channel_id: 33 },
    ],
  },
  { id: "5", content: "5", display_order: 0, items: [{ id: "Email Newsletter", content: "Email Newsletter", channel_id: 39 }] },
  {
    id: "7",
    content: "7",
    display_order: 0,
    items: [
      { id: "Mobile App", content: "Mobile App", channel_id: 42 },
      { id: "Mobile Alerts", content: "Mobile Alerts", channel_id: 45 },
    ],
  },
  { id: "6", content: "Other", items: [] },
];

export const projectChannelsData = [
  { channel_id: 0, display_order: 0, name: "Unassigned", color: { primary: "#000000", dark: "#0000000", light: "#000000" }, icon: "icon0" },
  {
    channel_id: 1,
    display_order: 1,
    name: "Social Media",
    color: { primary: "#70C1FF", dark: "#0484E6", light: "#DAEFFF" },
    icon: "icon1",
  },
  { channel_id: 2, display_order: 2, name: "Paid Ads", color: { primary: "#FEB867", dark: "#D17102", light: "#FFECD6" }, icon: "icon2" },
  { channel_id: 3, display_order: 3, name: "Influencer", color: { primary: "#FED649", dark: "#B37806", light: "#FDF9D9" }, icon: "icon3" },
  { channel_id: 4, display_order: 4, name: "Content", color: { primary: "#5C8DFF", dark: "#4B80FC", light: "#DBE6FF" }, icon: "icon4" },
  { channel_id: 5, display_order: 5, name: "Email", color: { primary: "#83E28D", dark: "#02A613", light: "#DFFFE2" }, icon: "icon5" },
  { channel_id: 7, display_order: 6, name: "Mobile", color: { primary: "#9892E1", dark: "#4B42B8", light: "#F3F3FE" }, icon: "icon7" },
  { channel_id: 6, display_order: 7, name: "Other", color: { primary: "#CFB5D5", dark: "#814D8E", light: "#F8F4F9" }, icon: "icon6" },
];


export const budgetBasedContentData = [
  {
    id: "1",
    day: "2023-01-01",
    platforms: ["instagram","facebook"],
    postideas: "Introduction to Brainvoy",
    caption: "Unlock your potential with",
    hashtags: ["#brainvoyage", "#unlockpotential"],
    formatting: "Reel",
    postingtime: "10:00 AM",
    budgetallocation: "50",
    creative: "Creative 1",
    automation: "Automation 1",
    status: "Draft",
  },
  {
    id: "2",
    day: "2023-02-01",
    platforms: ["facebook","instagram","twitter"],
    postideas: "Introduction",
    caption: "Unlock",
    hashtags: ["#brainvoyage"],
    formatting: "Reel",
    postingtime: "10:00 AM",
    budgetallocation: "50",
    creative: "Creative 1",
    automation: "Automation 1",
    status: "Draft",
  },
  {
    id: "3",
    day: "2023-04-01",
    platforms: ["facebook"],
    postideas: "Introduction to Brainvoy",
    caption: "Unlock your potential ",
    hashtags: ["#brainvoyage", "#unlockpotential"],
    formatting: "Reel",
    postingtime: "10:00 AM",
    budgetallocation: "50",
    creative: "Creative 1",
    automation: "Automation 1",
    status: "Draft",
  },
  {
    id: "4",
    day: "2023-01-01",
    platforms: ["facebook","instagram","twitter"],
    postideas: "Introduction ",
    caption: "Unlock your potential with",
    hashtags: [  "#brainvoyage"],
    formatting: "Reel",
    postingtime: "10:00 AM",
    budgetallocation: "50",
    creative: "Creative 1",
    automation: "Automation 1",
    status: "Draft",
  },
  {
    id: "5",
    day: "2023-05-01",
    platforms: ["facebook","instagram"],
    postideas: "Introduction to ",
    caption: "Unlock your potential ",
    hashtags: [ "#unlockpotential"],
    formatting: "Reel",
    postingtime: "10:00 AM",
    budgetallocation: "50",
    creative: "Creative 1",
    automation: "Automation 1",
    status: "Draft",
  },
]
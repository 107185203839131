import { useCallback, useRef, useState, createContext, useMemo, useEffect } from "react";
import "react-data-grid/lib/styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { read, utils } from "xlsx";

import {
  uploadCalendarRange,
  uploadCustomCategories,
  uploadDataCoordinates,
  uploadFileForArchive,
  uploadReviewBudgetToServer,
  uploadWorkSheet,
} from "api/budget";
import { IChannelData } from "api/budget/types";

import { ROUTES } from "constants/routes.constants";

import {
  convertBudgetReviewTableData,
  convertCategoriesToOriginalFormat,
  isCategoriesValid,
  makeEmptyContainerForChannel,
  Container,
  getContainerFromProjectChannels,
} from "utils/budget.utils";
import { errorWithLink, showCommonServerError } from "utils/modules.utils";
import { getRowsAccordingToCategories, ws_to_rdg } from "utils/sheets.utils";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useGetChannels } from "hooks/queries/channels";

import { setUploadedPoject } from "storage/slices/project-slice";
import { setIsCollapsed } from "storage/slices/sidebar-slice";

import SpinnerLoader from "shared/components/loader-screen/SpinnerLoader";
import Alert from "shared/components/toasts";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

//For Testing Purpose Only
// import { headerData, budgetData as BudgetDataa} from "./ConnectDataSource/components/jsonExamples";
import { cn } from "lib/utils";

import OverlayReviewBudget from "./ConnectDataSource/components/OverlayBudgetReview";
import ConfirmCategories from "./ConnectDataSource/ConfirmCategories";
import ConnectSource from "./ConnectDataSource/ConnectSource";
import LocateData from "./ConnectDataSource/LocateData";
import ProgressIndicator from "./ConnectDataSource/ProgressIndicator";
import ReviewBudget from "./ConnectDataSource/ReviewBudget";

import "./ConnectDataSource.css";

export const ConnectSourceContext = createContext<undefined | any>(undefined);

type DataCoordinatesType = {
  xAxis: number;
  yAxis: number;
  dataStarts: number;
};

const ConnectDataSource = (props: any) => {
  const dispatch = useAppDispatch();
  const { dataType } = useAppSelector(state => state.uploadedBudget);
  const { state } = useLocation();
  console.log("ConnectDataSource", props);
  const navigate = useNavigate();
  const [formStep, setFormStep] = useState<number>(1);
  // Connect a Source and Locate Data
  const [worksheetList, setWorksheetList] = useState<Array<string>>([]); // Full Workbook Uploaded self or Downloaded from google
  const [worksheetUuid, setWorksheetUuid] = useState("");
  const [selectedWorksheet, setSelectedWorksheet] = useState<null | string>(null); // Name of the selected worksheet
  const [fileName, setFileName] = useState<any>(""); //Meta data from sheets to show file list
  const [selectedGoogleFile, setSelectedGoogleFile] = useState("");
  // Locate Data
  const [row, setRow] = useState<any[]>([]);
  const [column, setColumn] = useState<any[]>([]);
  const [locateData, setLocateData] = useState<any>(null); // From Backend
  const [locateDataLoading, setLocateDataLoading] = useState(false);
  const [coordinates, setCoordinates] = useState<null | DataCoordinatesType>(null); // xAxis, yAxis, dataStarts to be sent to server

  // Confirm Categories
  const [categories, setCategories] = useState<null | Container[]>(null); // From Backend
  const { data: projectChannels } = useGetChannels();

  // Budget Review
  const [showBudgetReviewOverlay, setShowBudgetReviewOverlay] = useState(false);
  const [budgetData, setBudgetData] = useState<any>([]); //
  const [budgetDataHeader, setBudgetDataHeader] = useState<any>([]);
  const [budgedDataRow, setBudgetDataRow] = useState<any>([]);
  const [loadingReviewBudget, setLoadingReviewBudget] = useState<boolean>(false);
  const [reviewBudgetError, setReviewBudgetError] = useState<boolean>(false);
  const [uploadingReviewBudget, setUploadingReviewBudget] = useState<boolean>(false);
  const [hiddenRows, setHiddenRows] = useState<string[]>([]);
  const worksheets = useRef<any>(null);

  // ----- ----- Set/Change Worksheet on Mount or Change from Dropdown
  const changeWorksheet = useCallback((data: string) => {
    setSelectedWorksheet(data);
    const ws = worksheets.current.Sheets[data]; // get the first worksheet
    const { rows, columns } = ws_to_rdg(ws); // Convert Worksheet to Rows and Columns
    // Emulate Unmount using Settimeout - (This is done so that Table columns width auto adjusts on Worksheet change)
    setRow([]);
    setColumn([]);
    setTimeout(() => {
      setRow(rows);
      setColumn(columns);
    }, 0);
  }, []);

  const themeMappings = useCallback(
    (id: number | string): any => {
      const currentChannel = projectChannels?.find(channel => String(channel.channel_id) == String(id));
      return currentChannel;
    },
    [projectChannels],
  );

  const updateSeletedGoogleFile = useCallback((data: string) => {
    setSelectedGoogleFile(data);
  }, []);

  // ----- ----- Handle Upload XLSX file to server and show the table on the page
  const handleUploadFile = useCallback(async (file: any) => {
    setFileName(file?.name);
    worksheets.current = read(await file?.arrayBuffer());
    setWorksheetList(worksheets.current.SheetNames); // Save whole Worksheets Data
    changeWorksheet(worksheets.current.SheetNames[0]); // Set Current Worksheet To be shown
  }, []);

  const zeroizeCategories = useCallback(() => {
    const emptyCategories = (projectChannels || []).map(channel => {
      return makeEmptyContainerForChannel(channel);
    });
    setCategories(emptyCategories);
  }, [projectChannels]);

  // ----- ----- Upload the Worksheet to Server
  const uploadSheetToServer = useCallback(async () => {
    if (selectedWorksheet) {
      const wstoupload = worksheets.current.Sheets[selectedWorksheet];
      const csvData: string = utils.sheet_to_csv(wstoupload);
      const jsonData: string[] = utils.sheet_to_json(wstoupload, { header: 1 });
      const uuId = uuidv4();
      try {
        setLocateDataLoading(true);
        setWorksheetUuid(uuId);
        const apiData = {
          worksheetContents: jsonData,
          contextUuid: uuId,
        };
        setLocateData(null);
        //zeroizeCategories();
        setCategories(null);
        const locateDataRes = await uploadWorkSheet(apiData);
        setLocateData(locateDataRes.ranges);
        setLocateDataLoading(false);
        console.log("Locate Data Res", locateDataRes);
      } catch (err: unknown) {
        setLocateDataLoading(false);
        setFormStep(1);
        showCommonServerError(err);
      }

      // Upload For Archiving
      try {
        const csvFile = new File([csvData], fileName, { type: "text/csv" });
        const { response, error } = await uploadFileForArchive(csvFile, uuId);
        console.log("Upload Res", response);
        if (response.status === 200 || response.status === 201) {
          dispatch(setUploadedPoject(response?.data));
        }
      } catch (err) {}
    }
  }, [selectedWorksheet, fileName, setLocateDataLoading, setWorksheetUuid, setLocateData, zeroizeCategories, setFormStep, dispatch]);

  // Upload X-Axis, Y-Axis and Data Starts back to server
  const uploadCoordinatesToServer = useCallback(async () => {
    if (!coordinates) return;
    const apiData = {
      contextUuid: worksheetUuid,
      xAxis: coordinates.xAxis,
      yAxis: coordinates.yAxis,
      dataStarts: coordinates.dataStarts,
    };
    try {
      const response = await uploadDataCoordinates(apiData);
      console.log("Response for Uploading Coordinates", response);
    } catch (err) {
      console.log("Error in uploadCoordinatesToServer", err);
    }
  }, [coordinates, worksheetUuid]);

  // Save the user sorted categories to server
  const uploadUserSelectedCategories = useCallback(
    async (customCategories: any) => {
      const data = {
        mapping: customCategories,
        contextUuid: worksheetUuid,
      };
      try {
        const response = await uploadCustomCategories(data);
        console.log("uploadUserSelectedCategories response", response);
      } catch (err) {
        console.log("Error in uploadUserSelectedCategories", err);
      }
    },
    [worksheetUuid],
  );

  const getNumericalDataFromCells = (cell: any) => {
    if (!cell) return 0;
    cell = cell.toString();
    let number = 0;
    if (cell.includes("$") || cell.includes(",")) {
      number = parseInt(cell.replace("$", "").replace(",", ""));
    } else {
      number = parseInt(cell);
    }
    return number ? number : 0;
  };

  // Combining Data from Locate Data Table and Categories create data structure for Budget Review Table.
  // Additional Upload User Sorted Categories to server
  const fetchBudgetTableData = useCallback(async (): Promise<void> => {
    if (!categories || !coordinates) return;

    try {
      setLoadingReviewBudget(true);
      setShowBudgetReviewOverlay(true);
      setReviewBudgetError(false);
      const { originalCustomData, originalDataNameOnly } = convertCategoriesToOriginalFormat(categories);
      // Api Call Here
      console.log("Original Custom Data", originalCustomData);
      console.log("Original Data Name Only", originalDataNameOnly);
      if (!isCategoriesValid(originalDataNameOnly)) {
        Alert("info", "Please check if you have selected correct categories before continuing", "No categories found");
        setFormStep(3);
        return;
      }

      uploadUserSelectedCategories(originalCustomData);
      const dataRowList = getRowsAccordingToCategories(row, originalDataNameOnly, coordinates?.yAxis);
      const xAxisLabels = row[coordinates.xAxis].filter((label: any) => typeof label === "string");
      const apiData = {
        labels: xAxisLabels,
        contextUuid: worksheetUuid,
      };
      console.log("Data to send to api", apiData);
      let response: any;
      try {
        response = await uploadCalendarRange(apiData);
      } catch (err) {
        errorWithLink(err, () => {
          fetchBudgetTableData();
        });
        setLoadingReviewBudget(false);
        setShowBudgetReviewOverlay(false);
        setReviewBudgetError(true);
        return;
      }
      const monthNameToDateMapping: any = {};
      const monthOnly = response.mapping.filter((item: any) => item.category === "Month");
      const yearMonthList = monthOnly.map((item: { time_period: string; year_month: string }) => {
        monthNameToDateMapping[item.year_month] = item.time_period;
        return item.year_month;
      }); // Table Header
      const monthOnlyList = monthOnly.map((item: any) => item.time_period);
      console.log("Mapping Name and Data", monthNameToDateMapping);
      setBudgetDataHeader(["Channel", ...yearMonthList, "Total"]);
      const relevantIndex: any = [];
      row[coordinates.xAxis].forEach((item: any, index: number) => {
        if (item && monthOnlyList.includes(item)) {
          relevantIndex.push(index);
        }
      });
      const childParentRelationship: any = {};
      const childChannelIdMapping: any = {};
      originalCustomData.forEach(item => {
        childParentRelationship[item.child] = item.project_channel_id;
        childChannelIdMapping[item.child] = item.channel_id;
      });
      const parentNames: string[] = [];
      const realData = dataRowList.map((item: any) => {
        const data: any = {};
        for (const [index, cell] of item.entries()) {
          if (childParentRelationship[cell]) {
            if (!parentNames.includes(childParentRelationship[cell])) {
              //For Later Use
              parentNames.push(childParentRelationship[cell]);
            }

            data["id"] = uuidv4();
            data["parentId"] = childParentRelationship[cell];
            data["name"] = cell;
            data["channel_id"] = childChannelIdMapping[cell];
          }
          if (relevantIndex.includes(index)) {
            console.log("MOnth", yearMonthList[relevantIndex.indexOf(index)]);
            console.log("Date", monthNameToDateMapping[yearMonthList[relevantIndex.indexOf(index)]]);
            console.log("Data", cell);
            data[yearMonthList[relevantIndex.indexOf(index)]] = getNumericalDataFromCells(cell);
          }
        }
        return data;
      });
      const parentRows = parentNames.map(item => {
        return {
          id: item,
          name: themeMappings(item)?.name,
          expanded: true,
          isParent: true,
          project_channel_id: item,
          ...yearMonthList.reduce((acc: any, month: any) => ({ ...acc, [month]: 0 }), {}),
        };
      });

      const newRealData = [...realData];
      newRealData.sort((a: any, b: any) => {
        const nameA = a.parentId.toUpperCase(); // ignore upper and lowercase
        const nameB = b.parentId.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      console.log("Sorted Original Data", newRealData);
      const sortedRealData = JSON.parse(JSON.stringify(newRealData));
      parentRows.map(item => {
        const ind = sortedRealData.findIndex((row: any) => row.parentId === item.id);
        console.log("Index" + ind, "Id:" + item.id);
        sortedRealData.splice(ind, 0, item);
      });
      console.log("Sorted Real Data", sortedRealData);
      setBudgetData(sortedRealData);
      setLoadingReviewBudget(false);
    } catch (err) {
      console.log("Error in fetchBudgetTableData", err);
      setLoadingReviewBudget(false);
      setReviewBudgetError(true);
      setShowBudgetReviewOverlay(false);
      Alert("error", "Please check your data and try again", "Something went wrong!");
    }
  }, [categories, coordinates, uploadUserSelectedCategories, row, worksheetUuid, themeMappings]);

  const uploadReviewBudget = useCallback(async () => {
    const data = {
      data: convertBudgetReviewTableData(budgedDataRow, hiddenRows),
      contextUuid: worksheetUuid,
    };
    try {
      setUploadingReviewBudget(true);
      const response = await uploadReviewBudgetToServer(data);
      console.log("uploadReviewBudget Response", response);
      setTimeout(() => {
        setUploadingReviewBudget(false);
        navigate(ROUTES.budgetForm, { state: { fileName } });
      }, 1000);
    } catch (error) {
      setUploadingReviewBudget(false);
      console.log("Error in uploadReviewBudget", error);
    }
  }, [budgedDataRow, worksheetUuid, setUploadingReviewBudget, navigate]);
  const updateHiddenRows = useCallback((id: string) => {
    setHiddenRows(prev => {
      if (prev.includes(id)) {
        return prev.filter(item => item !== id);
      }
      return [...prev, id];
    });
  }, []);
  const setDataCoordinates = useCallback((dataCoords: DataCoordinatesType) => {
    console.log("setDataCoordinates", dataCoords);
    setCoordinates(dataCoords);
  }, []);

  const setDataCategories = useCallback(
    (data: Container[] | null) => {
      console.log("setDataCategories before", data);
      if (!data) return;

      const categories = (projectChannels || []).map(channel => getContainerFromProjectChannels(channel, data));
      console.log("setDataCategories after", categories);
      setCategories(categories);
    },
    [projectChannels],
  );

  const setDataBudgetRow = useCallback((data: any) => {
    setBudgetDataRow(data);
  }, []);

  const handleNext = useCallback(
    (step: number) => {
      // if(!selectedWorksheet) return
      switch (step) {
        case 2: {
          uploadSheetToServer(); // SpreadSheet Upload (Receive AI Coordinates)
          setFormStep(2);
          break;
        }
        case 3: {
          uploadCoordinatesToServer(); // X-Axis, Y-Axis, Data Starts Upload (Receive AI Categories)
          setFormStep(3);
          break;
        }
        case 4: {
          fetchBudgetTableData(); // X-Axis Upload (Receive AI Filtered Columns)
          setFormStep(4);
          break;
        }
        case 5: {
          uploadReviewBudget();
          break;
        }
      }
    },
    [uploadSheetToServer, uploadCoordinatesToServer, fetchBudgetTableData, uploadReviewBudget],
  );

  const handleBack = useCallback(() => {
    setFormStep(prev => {
      const newFormStep = prev - 1;
      switch (newFormStep) {
        case 1: {
          setCoordinates(null);
          break;
        }
        case 2: {
          //zeroizeCategories();
          setCategories(null);
          break;
        }
        case 3: {
          setBudgetDataRow([]);
          setBudgetData([]);
          break;
        }
      }
      if (prev > 1) return newFormStep;
      return prev;
    });
  }, [zeroizeCategories]);

  const resetData = useCallback(() => {
    setRow([]);
    setColumn([]);
    setSelectedWorksheet(null);
    setWorksheetList([]);
    setFileName("");
    setCategories(null);
    setCoordinates(null);
    setLocateData(null);
    setBudgetDataRow([]);
    setBudgetData([]);
  }, []);
  useEffect(() => {
    setFormStep(1);
    resetData();
  }, [dataType, state?.key]);
  useEffect(() => {
    // dispatch(setIsCollapsed(true))
  }, []);
  // Enable/ Disable Next Button
  const enableNextBtn = useMemo(() => {
    switch (formStep) {
      case 1:
        if (selectedWorksheet) {
          return true;
        }
        return false;
      case 2:
        if (coordinates) return true;
        return false;
      case 3:
        if (categories) return true;
        return false;
      case 4:
        if (budgedDataRow?.length > 0) return true;
        return false;
      default:
        // By default Disable Next Btn
        return false;
    }
  }, [selectedWorksheet, formStep, coordinates, categories, budgedDataRow]);
  // For Testing Purpose Only
  // useEffect(()=>{
  //   setBudgetData(BudgetDataa)
  // },[])
  const contextValue = {
    handleUploadFile,
    selectedWorksheet,
    changeWorksheet,
    worksheetList,
    fileName: fileName,
    selectedGoogleFile,
    updateSeletedGoogleFile,
    row,
    column,
    resetData,
    locateData,
    locateDataLoading,
    setDataCoordinates,
    categories,
    setCategories: setDataCategories,
    coordinates,
    worksheetUuid,
    budgetData,
    budgetDataHeader,
    budgedDataRow,
    setDataBudgetRow,
    setBudgetData,
    hiddenRows,
    updateHiddenRows,
    loadingReviewBudget,
    reviewBudgetError,
    fetchBudgetTableData,
  };

  if (uploadingReviewBudget) return <SpinnerLoader />;
  return (
    <DashboardLayout title='Connect data source'>
      <div className='flex flex-col h-full'>
        <div className='bg-white'>
          <ProgressIndicator formStep={formStep} enableNext={enableNextBtn} setFormStep={handleNext} handleBack={handleBack} />
        </div>
        <div
          className={cn(
            "flex h-full w-full bg-[#F7F9FB] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600",
          )}
        >
          <div className='w-full'>
            <ConnectSourceContext.Provider value={contextValue}>
              {formStep === 1 ? <ConnectSource /> : <></>}
              {formStep === 2 ? <LocateData /> : <></>}
              {formStep === 3 ? <ConfirmCategories /> : <></>}
              {formStep === 4 ? <ReviewBudget /> : <></>}
            </ConnectSourceContext.Provider>
          </div>
        </div>
      </div>

      {showBudgetReviewOverlay && (
        <OverlayReviewBudget
          onClick={() => {
            setShowBudgetReviewOverlay(false);
          }}
        />
      )}
    </DashboardLayout>
  );
};

export default ConnectDataSource;

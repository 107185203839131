import { ReactComponent as GoogleSheetsIcon } from "assets/icons/excelSheet.svg";
import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg";
import { ReactComponent as GoogleAnalyticsIcon } from "assets/icons/googleAnalytics.svg";
import { ReactComponent as HubspotIcon } from "assets/icons/hubspot.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";
import { ReactComponent as QuickbooksIcon } from "assets/icons/quickbooks.svg";
import { ReactComponent as SalesForceIcon } from "assets/icons/salesforce.svg";
import { ReactComponent as ShopifyIcon } from "assets/icons/shopify.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/twitter.svg";
import { ReactComponent as XeroIcon } from "assets/icons/xero.svg";
import { ReactComponent as ZendeskIcon } from "assets/icons/zendesk.svg";

export const INTEGRATION_ICONS = {
  facebook: FacebookIcon,
  hubspot: HubspotIcon, //verified
  instagram: InstagramIcon,
  salesforce: SalesForceIcon,
  twitter: TwitterIcon,
  zendesk: ZendeskIcon,
  quickbooks: QuickbooksIcon,
  xero: XeroIcon,
  "google-sheets": GoogleSheetsIcon, //verified
  "google-analytics": GoogleAnalyticsIcon, //verified
  shopify: ShopifyIcon,
};

import { useEffect, useMemo } from "react";

import { AgentItem } from "api/agent/type";

import { AGENT_IMAGES } from "constants/agent.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { getAgentsList } from "storage/actions/agent";

import { Breadcrumbs } from "shared/components/breadcrumbs/Breadcrumbs";
import { Button } from "shared/components/buttons/Button";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import { cn } from "lib/utils";

export default function Agents() {
  const dispatch = useAppDispatch();

  const agents = useAppSelector(state => state.agent.agents);

  const emmaAgent = useMemo(() => {
    if (!agents) return null;

    return agents.find((agent: AgentItem) => agent.id === 1);
  }, [agents]);

  useEffect(() => {
    dispatch(getAgentsList({ page: 1 }));
  }, []);

  return (
    <DashboardLayout
      title={emmaAgent?.title ?? "Browse agents"}
      headerExtraContent={
        emmaAgent ? (
          <div className='ml-6 flex items-center gap-6'>
            <div className='h-[26px] w-[1px] bg-[#E9EAEC]'></div>
            <h3 className='text-[#1F2228] font-semibold text-base'>Browse Agents</h3>
          </div>
        ) : null
      }
      containerClassName='bg-white'
    >
      <div className='p-6'>
        <Breadcrumbs
          breadcrumbs={[
            {
              path: "/",
              label: "Home",
              active: false,
            },
            {
              path: "",
              label: "Browse agents",
              active: true,
            },
          ]}
        />
        <div className='mt-3 flex flex-col mb-8'>
          <h1 className='text-2xl text-[#333740] font-semibold'>Browse all agents</h1>
        </div>
        <div className={cn("grid grid-cols-1 md:gap-6 md:grid-cols-2")}>
          {agents.length > 0 ? agents.map((agent, index) => <AgentCard key={index} agent={agent} />) : <div></div>}
        </div>
      </div>
    </DashboardLayout>
  );
}

const AgentCard = ({ agent }: { agent: AgentItem }) => {
  const Image = AGENT_IMAGES?.[agent?.image_key as keyof typeof AGENT_IMAGES];

  return (
    <div className='flex flex-shrink-0 bg-white border border-[#E9EAEC] rounded-[1.25rem] p-6 gap-x-10 w-full shadow-[0px_0px_4px_0px_rgba(0,0,0,0.03)] min-h-[219px]'>
      <img src={Image} alt={agent.name} className='w-auto !h-[181px] rounded-[16px]' />
      <div className='flex flex-col h-full justify-between w-full gap-4'>
        <div className='flex flex-col gap-2'>
          <h1 className='font-semibold text-base text-black1'>{agent.title}</h1>
          <p className='font-medium text-sm text-grey1'>{agent.description}</p>
          <ul className='flex gap-x-1'>
            <li className='bg-[#F7F7F8] text-[#333740] py-1.5 px-2.5 text-xs font-semibold rounded-lg flex items-center gap-1'>
              <span className='bg-[#333740] w-1.5 h-1.5 rounded-full'></span>
              {agent.title}
            </li>
          </ul>
        </div>
        <div className='flex justify-end'>
          <AgentCardBtn agent={agent} />
        </div>
      </div>
    </div>
  );
};

export const AgentCardBtn = ({ agent }: { agent: AgentItem }) => {
  const isActivated = useMemo(() => {
    return agent?.id === 1;
  }, [agent]);

  return (
    <Button
      disabled={isActivated}
      className={cn(
        "before:!border-none !w-[128px]",
        isActivated ? "disabled:bg-activated-btn bg-white text-[#1F2228] disabled:text-[#1F2228] hover:bg-activated-btn" : "!bg-[#1F2228]",
      )}
    >
      {isActivated ? "Activated" : "Activate Me"}
    </Button>
  );
};

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getUsersCompanyProfile } from "api/companies";

import { IChannelData } from "../../api/budget/types";

interface CompanyState {
  companyDetails: any;
  loading: boolean;
  error: string | null;
}

const initialState: CompanyState = {
  companyDetails: null,
  loading: false,
  error: "",
};
export const getCompanyProfile = createAsyncThunk<any>("integrations/getCompanyProfile", async () => {
  try {
    const response = await getUsersCompanyProfile();
    return response;
  } catch (error: any) {
    console.error(error);
    return;
  }
});

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCompanyProfile.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCompanyProfile.fulfilled, (state, action: any) => {
        state.loading = false;
        state.companyDetails = action.payload;
      })
      .addCase(getCompanyProfile.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload || "Unknown error";
      });
  },
});

export const {} = companySlice.actions;
export const channelsReducer = companySlice.reducer;

import { useState } from "react";

import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrowLeft.svg";
import { ReactComponent as ArrowRightIcon } from "assets/icons/arrowRight.svg";
import { ReactComponent as CalendarIcon2 } from "assets/icons/calendar-2.svg";
import { ReactComponent as SearchIcon } from "assets/icons/searchGlass.svg";

const SubHeader = ({ search, updateSearch }: any) => {
  return (
    <div className=' justify-between w-full px-6 py-4 bg-[#F7F7F8]  md:block lg:flex'>
      <div className='flex gap-4 items-center '>
        <p className='font-bold text-2xl text-[#333740]'>Budget-based content calendar ideas</p>

        <p className='text-xs font-medium text-[#717684] flex items-center transform translate-y-1'>
          <button>
            <ArrowLeftIcon className='h-3.5 w-3.5' />
          </button>{" "}
          2/2{" "}
          <button>
            <ArrowRightIcon className='h-3.5 w-3.5 opacity-50' />
          </button>
        </p>
      </div>
      <div className='flex gap-4 items-center sm:mt-3 md:mt-4 lg:mt-0'>
        <div className='relative flex items-center gap-x-2 bg-white rounded-xl w-[280px] px-3 py-[11px] border border-[#E9EAEC]'>
          <SearchIcon className='text-[#322D60]/70' />
          <input
            type='search'
            className='w-full outline-none'
            placeholder='Search'
            required
            value={search}
            onChange={e => updateSearch(e.target.value)}
          />
        </div>

        <div className="">
          <div className='flex items-center text-sm leading-default font-medium text-[#333740] gap-3'>
            <button className='h-8 w-8 flex justify-center items-center'>
              <ArrowLeft className='h-8 w-8' />
            </button>
            <div>
              <p>December, 2024</p>
            </div>
            <button className='h-8 w-8 flex justify-center items-cente'>
              <ArrowLeft className="h-8 w-8 rotate-180"  />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubHeader;

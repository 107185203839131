import { ICredentialData } from "interfaces/integrations.interfaces";
import { Meta } from "interfaces/meta.interfaces";
import { StringOrNumber } from "interfaces/shared-types.interfaces";

import api from "..";

const BASE_API = process.env.REACT_APP_API_URL;

export const getConnectSourceList = (): Promise<any[]> => {
  return api(`${BASE_API}/integrations`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const getConnectedSourceList = (): Promise<{ data: any[] }> => {
  return api(`${BASE_API}/connections`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const generateCredentialForAppIntegration = (id: number, shopUrl?: string): Promise<any> => {
  let url = `${BASE_API}/credentials/auth-url/${id}`;

  if (shopUrl) {
    url += `?shop_url=${shopUrl}`;
  }

  return api(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const saveOauth2Credential = (code: string, state: string): Promise<any> => {
  return api(`${BASE_API}/credentials/oauth-callback?code=${code}&state=${encodeURIComponent(state)}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const getCredential = (id: number): Promise<ICredentialData> => {
  return api(`${BASE_API}/credentials/refresh/${id}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
};

export const createConnection = (data: any): Promise<any> => {
  return api(`${BASE_API}/connections`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
};

export const refreshConnection = (id: StringOrNumber): Promise<any> => {
  return api(`${BASE_API}/connections/${id}/sync`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
};

export const pauseConnection = (id: StringOrNumber, data: { enabled?: boolean; account_name?: string }): Promise<any> => {
  return api(`${BASE_API}/connections/${id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
};

export const removeConnection = (id: StringOrNumber): Promise<any> => {
  return api(`${BASE_API}/connections/${id}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  });
};

export const getConnectedData = (id: StringOrNumber): Promise<any> => {
  return api(`${BASE_API}/connections/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};
